import url from "@/utils/url";

export default {
  id: "red-hydrogen-one",
  title: "Hydrogen One",
  subtitle: "RED Smart Phone",
  projectUrl: null,
  color: {
    highlight: "hsl(0, 0%, 90%)",
    light: "hsl(0, 0%, 80%)",
    dark: "hsl(0, 0%, 15%)",
    gradient: "linear-gradient(45deg, hsl(0, 0%, 85%), hsl(0, 0%, 90%))",
  },
  thumb: {
    src: url("red/red-thumb.webp"),
    width: 800,
    height: 1280,
  },
  hero: {
    src: url("red/red-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: `RED Hydrogen One was an Android smartphone by Red Digital Cinema that released in August 2018 on the Verizon Wireless network. The task was to design and develop a landing page for Red's Hydrogen One smart phone.`,
    },
    {
      title: "Roles",
      list: ["UI Design", "Front-end Development"],
    },
    {
      title: "Agency",
      text: `Magical Threads`,
    },
  ],
  breakdown: [
    {
      media: {
        videos: [
          {
            deviceType: false,
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/red%2Fred-desktop-2.mp4?alt=media&token=4191df7d-f852-42f8-82cd-bc880df42e98",
            placeholder: url("red/red-desktop-placeholder-2.webp"),
            alt: "Experimental",
          },
        ],
      },
      layout: "layout-ipad",
      bg: false,
      // style: {
      //     padding: '0 var(--gutted)'
      // }
    },
    {
      text: {
        subtitle: "Product Landing Pages",
        summary: `A series of landing pages for Red. Due to delays and negative reviews, the project was ultimately canceled. <br><br>This typography pairing of a warped Machine typeface that was done by Red. The Helvetica font pairing was a specific demand from the client. These were some of the results.`,
      },
      media: {
        images: [
          {
            src: url("red/red-v9-overview.webp"),
            alt: "Overview Page",
            style: { marginBottom: "var(--gutted)" },
          },
        ],
      },
      layout: "layout-full",
    },
    {
      media: {
        images: [
          [
            {
              src: url("red/red-v8-overview.webp"),
              alt: "Overview Page",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v8-feature.webp"),
              alt: "Feature Page",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v5.1.webp"),
              alt: "Overview Page",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-store.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v3-5.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v3-3.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
          ],
          [
            {
              src: url("red/red-v9-view.webp"),
              alt: "Display Page",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v6-view.webp"),
              alt: "Display Page",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v8-specs.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v9-dna.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v3-1.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("red/red-v3-2.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
          ],
        ],
      },
      layout: "layout-two-col",
    },
    {
      media: {
        videos: [
          {
            deviceType: false,
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/red%2Fred-desktop.mp4?alt=media&token=fb8982df-9e4e-42f1-96d2-168a0ea14b07",
            placeholder: url("red/red-desktop-placeholder.webp"),
            alt: "Prototype Build",
          },
        ],
      },
      layout: "layout-ipad",
      bg: false,
      // style: {
      //     padding: '0 var(--gutted)'
      // }
    },
    {
      text: {
        subtitle: "Headline Advertisement Design",
      },
      media: {
        images: [
          [
            {
              src: url("red/red-headline-1.webp"),
              alt: "",
              style: {
                paddingLeft: "var(--gutted)",
                marginBottom: "var(--gutted)",
              },
            },
            {
              src: url("red/red-headline-2.webp"),
              alt: "",
              style: { paddingLeft: "var(--gutted)" },
            },
          ],
          [
            {
              src: url("red/red-headline-3.webp"),
              alt: "",
              style: {
                paddingLeft: "var(--gutted)",
                marginBottom: "var(--gutted)",
              },
            },
            {
              src: url("red/red-headline-4.webp"),
              alt: "",
              style: { paddingLeft: "var(--gutted)" },
            },
          ],
        ],
      },
      layout: "layout-two-col",
    },
    {
      text: {
        subtitle: "Icon Set",
      },
      media: [
        { src: url("red/red-icon-1.webp") },
        { src: url("red/red-icon-2.webp") },
        { src: url("red/red-icon-3.webp") },
        { src: url("red/red-icon-4.webp") },
        { src: url("red/red-icon-5.webp") },
        { src: url("red/red-icon-6.webp") },
        { src: url("red/red-icon-7.webp") },
        { src: url("red/red-icon-8.webp") },
        { src: url("red/red-icon-9.webp") },
        { src: url("red/red-icon-10.webp") },
        { src: url("red/red-icon-11.webp") },
        { src: url("red/red-icon-12.webp") },
      ],
      layout: "layout-three-col",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
