<template >
	<nav class='app-navigation'>
		<AppLogo 
			ref='topLogo' 
			class='logo-top link'
			aria-label='Mario Luevanos, Designer &amp; Developer'
			@click.native='onLogoClick'/>
		<router-link to='/' class='font-weight-regular link'>Work</router-link>
		<router-link to='/about' class='font-weight-regular link'>About</router-link>
		<NavMarker/>
	</nav>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue';
import NavMarker from '@/components/NavMarker.vue';

export default {
	name: 'app-navigation',
	components: {
		AppLogo,
		NavMarker
	},
	methods: {
		onLogoClick() {
			if (this.$route.name !== 'work') {
				this.$router.push({ name: 'work' });
			}
		}
	}
}
</script>

<style lang='scss' scoped>

@import '../styles/mixins';

.app-navigation {
	position: absolute;
	top: vh(50); 
	left: vw(var(--gutter));
	right: vw(var(--gutter));
	z-index: 2;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	min-height: vw(48);
	a {
		user-select: none;
		position: relative;
		z-index: 1;
	}
	a:not(:last-of-type) {
		margin-right: vw(30);
	}
}

svg.logo-top {
	pointer-events: all;
	cursor: pointer;
	fill: var(--theme-dark);
	position: absolute;
	top: 0;
	left: 0;
}

</style>
