import url from "@/utils/url";

export default {
  id: "residence-11",
  title: "Residence 11",
  subtitle: "New Age Magazine",
  projectUrl: "https://residence11.com",
  color: {
    highlight: "hsla(287, 15%, 90%, 1)",
    light: "hsl(278, 15%, 82%)",
    dark: "hsl(287, 100%, 10%)",
    gradient:
      "linear-gradient(45deg, hsla(287, 20%, 70%, 1), hsla(287, 15%, 90%, 1))",
  },
  thumb: {
    src: url("r11/r11-thumb.webp"),
    width: 800,
    height: 1280,
  },
  hero: {
    src: url("r11/r11-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "Residence 11 celebrates sensuality, sexuality, romance, and loving in a new age of human communication. It helps readers find a more healthy, honest, joyful and fulfilling physical and spiritual communication in their relationships. The task was to create an identity and content portal.",
    },
    {
      title: "Roles",
      list: [
        "Logo Design",
        "Web Design",
        "Front-end Development",
        "Back-end Development",
      ],
    },
    {
      title: "Agency",
      text: "Magical Threads",
    },
  ],
  breakdown: [
    {
      media: {
        videos: [
          {
            deviceType: "ipad",
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/r11%2Fr11-deskop.mp4?alt=media&token=99d059ad-0121-4621-939b-8576f04cbf6f",
            placeholder: url("r11/r11-desktop-placeholder.webp"),
            alt: "",
          },
        ],
      },
      layout: "layout-ipad",
      bg: false,
    },
    {
      text: {
        subtitle: "Logo Design",
        summary: `The wordmark is a serif typeface with a stylistically different set of characters as to imply diversity and inclusion, except for number eleven which means "one-of-the-same".`,
      },
      media: {
        images: [{ src: url("r11/r11-logo.webp"), alt: "Logo Design" }],
      },
      layout: "layout-full",
      style: {
        paddingBottom: "var(--gutted)",
      },
    },
    {
      media: {
        images: [
          [
            {
              src: url("r11/r11-logo-white.webp"),
              style: { paddingLeft: "var(--gutted)" },
            },
          ],
          [{ src: url("r11/r11-mark.webp"), alt: "Favicon" }],
        ],
      },
      layout: "layout-two-col",
    },
    {
      text: {
        subtitle: "Typography",
        summary: `A font pairing match between Neue Kabel's geometric body and Tiffany Std Bold exaggerated serifs creates a striking contrast. Tiffany Std utilized in section headers and page titles, where as Neue Kabel is used for the majority of the website's interface.`,
      },
      media: {
        images: [
          { src: url("r11/r11-type-1.webp"), alt: "Neue Kabel Bold" },
          { src: url("r11/r11-type-2.webp"), alt: "Tiffany Std Bold" },
        ],
      },
      style: {
        paddingBottom: "var(--gutted)",
      },
      layout: "layout-full",
    },
    {
      media: {
        images: [{ src: url("r11/r11-color-pallet.png") }],
      },
      layout: "layout-full",
    },
    {
      text: {
        subtitle: "Desktop Designs",
      },
      media: {
        images: [
          [
            { src: url("r11/r11-site-1.webp"), alt: "Home Page" },
            {
              src: url("r11/r11-site-2.webp"),
              style: { paddingLeft: "var(--gutted)" },
            },
            {
              src: url("r11/r11-site-3.webp"),
              style: { paddingLeft: "var(--gutted)" },
            },
            {
              src: url("r11/r11-site-4.webp"),
              style: { paddingLeft: "var(--gutted)" },
            },
            {
              src: url("r11/r11-site-5.webp"),
              style: {
                paddingLeft: "var(--gutted)",
                marginBottom: "var(--gutted)",
              },
            },
            {
              src: url("r11/r11-articles.webp"),
              alt: "Articles Landing Page",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("r11/r11-short-stories.webp"),
              alt: "Short Stories Landing Page",
              style: { marginBottom: "var(--gutted)" },
            },
            { src: url("r11/r11-news.webp"), alt: "News Landing Page" },
          ],
          [
            {
              src: url("r11/r11-article-default.webp"),
              alt: "Article Page—Default",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("r11/r11-article-centered.webp"),
              alt: "Article Page—Centered",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("r11/r11-article-interview.webp"),
              alt: "Article Page—Interview",
              style: { marginBottom: "var(--gutted)" },
            },
            { src: url("r11/r11-contributors.webp"), alt: "Contributors" },
          ],
        ],
      },
      layout: "layout-two-col",
    },
    {
      text: {
        subtitle: "Mobile Designs",
        summary:
          "Residence 11 is fully responsive, from desktop down to the smallest mobile phones.",
      },
      media: [
        { src: url("r11/r11-mobile-1.webp") },
        { src: url("r11/r11-mobile-2.webp") },
        { src: url("r11/r11-mobile-3.webp") },
        { src: url("r11/r11-mobile-4.webp") },
        { src: url("r11/r11-mobile-5.webp") },
        { src: url("r11/r11-mobile-6.webp") },
      ],
      layout: "layout-three-col",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
