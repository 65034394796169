import { TimelineMax, Expo, Sine } from "gsap";

import updateTransitionTheme from "@/utils/updateTransitionTheme";
import defaultTheme from "@/store/defaultTheme";

export default function onLeave({ next = () => {}, from, to }) {
  this.$store.commit("SET_ROUTE_FROM", from);

  const el = this.$el;
  const transDiv = document.getElementById("app-transition-div");
  const transLogo = transDiv.querySelector(".app-logo");
  const appFooter = document.getElementById("app-footer");
  const isWork = el.classList.contains("work");
  const xAmount = window.innerWidth * 0.1;
  const transClass = "transitioning";
  const fromClass = "from-" + el.className;
  const dur = 0.6;

  new TimelineMax({
    onStart: () => {
      document.documentElement.classList.add(transClass);
      document.documentElement.classList.add(fromClass);
    },
    onComplete: () => {
      document.documentElement.classList.remove(transClass);
      document.documentElement.classList.remove(fromClass);
      next();
    },
  })
    .add(updateTransitionTheme.bind(this, to), 0)
    .fromTo(
      transDiv,
      dur,
      {
        clipPath: isWork ? "inset(0 100% 0 0)" : "inset(0 0 0 100%)",
      },
      {
        clipPath: isWork ? "inset(0 0% 0 0)" : "inset(0 0 0 0%)",
        ease: Expo.easeIn,
      },
      0
    )
    .fromTo(
      el,
      dur,
      {
        autoAlpha: 1,
        x: 0,
      },
      {
        autoAlpha: 0,
        x: isWork ? xAmount : -xAmount,
        ease: Expo.easeIn,
      },
      0
    )
    .fromTo(
      appFooter,
      dur,
      {
        clipPath: isWork ? "inset(0 0 0 0%)" : "inset(0 0% 0 0)",
      },
      {
        clipPath: isWork ? "inset(0 0 0 100%)" : "inset(0 100% 0 0)",
        ease: Expo.easeIn,
      },
      0
    )
    .add(updateTheme.bind(this, to))
    .add("last")
    .fromTo(
      transLogo,
      `${dur + 0.3}`,
      {
        scale: 1.5,
      },
      {
        scale: 1,
        ease: Sine.easeInOut,
      },
      `last-=0.3`
    )

    // plays last
    .to(
      transDiv,
      dur,
      {
        clipPath: isWork ? "inset(0 0 0 100%)" : "inset(0 100% 0 0)",
        ease: Expo.easeIn,
      },
      `last`
    );
}

function updateTheme(to) {
  const currentProject =
    this.$store.getters.projects.find((p) => p.id === to.params.id) || {};
  const themeColor =
    to.name === "work-id" ? currentProject.color : defaultTheme();
  this.$store.dispatch("updateTheme", themeColor);
}
