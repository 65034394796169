<template>
    <div id='app-transition-div'>
        <AppLogo/>
    </div>
</template>

<script>
import AppLogo from '@/components/AppLogo';

export default {
    name: 'app-transition-div',
    components: {
        AppLogo
    }
}
</script>
<style lang='scss'>
@import '../styles/mixins.scss';
@import '../styles/vars.scss';

#app-transition-div {
    @include max-area;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--theme-dark);
    clip-path: inset(0 100% 0 0);
    pointer-events: none;
    user-select: none;
    z-index: 10;
    display: none;
    .app-logo {
        width: 10vw;
        fill: var(--theme-highlight);
        max-width: none;
    }
}

.transitioning {
    overflow: hidden;
    #app-transition-div {
        display: flex;
    }
}

</style>
