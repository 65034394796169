export default function socialLinks() {
  const links = [
    {
      name: "CodePen",
      href: "https://codepen.io/marioluevanos",
    },
    {
      name: "Twitter",
      href: "https://twitter.com/bullzito",
    },
    {
      name: "GitHub",
      href: "https://github.com/marioluevanos",
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/in/marioluevanos",
    },
  ];

  if (process.env.NODE_ENV === "development") {
    links.push({
      name: "Admin",
      href: "/admin",
    });
  }
  return links;
}
