// Query the meta tag once
const metaThemeColor = Array.from(
  document.documentElement.firstChild.children
).find((el) => el.getAttribute("name") == "theme-color");

export default function setMetaThemeColor(highlight) {
  const root = getComputedStyle(document.documentElement);
  const themeDark = root.getPropertyValue("--theme-dark");
  highlight = /var/g.test(highlight) ? themeDark : highlight;
  metaThemeColor.setAttribute("content", highlight);
}
