<template>
    <span class='nav-marker' :style='markerCSS' style='opacity: 0'>
        <i class='marker' style='transition: none'/>
    </span>
</template>

<script>
import { TimelineMax } from 'gsap';
import debounce from '@/utils/debounce';

export default {
    name: 'nav-marker',
    data () {
        return {
            markerPosition: {
                width: 0,
                x: 0
            },
            activeClass: '.router-link-exact-active'
        }
    },
    watch: {
        '$route'(to, from) {
            if (!from.name) return; // Only to fire when changing route, not on initial mount
            this.$nextTick(_ => this.updateMarkerPosition());
        }
    },
    computed: {
        markerCSS () {
            return {
                width: `${ this.markerPosition.width }px`,
                transform: `translate3d(${ this.markerPosition.x }px, 0, 0)`
            }
        }
    },
    methods: {
        updateMarkerPosition () {
            const parent = this.$el.parentElement;
            const link = parent.querySelector(this.activeClass) || parent.querySelector('.router-link-active');
            if(!link) return;
            const scale = 20;
            this.markerPosition.width = link.clientWidth + scale;
            this.markerPosition.x = link.offsetLeft - (scale/2);
        },
        addEvents() {
            this.$el.addEventListener('transitionend', animateIn);

            window.addEventListener('resize', debounce(this.updateMarkerPosition, 250));

            // Only works when a timer is set, fires after the screen has settled
            window.addEventListener('orientationchange', () => setTimeout(() => this.updateMarkerPosition(), 1000));
        },
        initMarkerPosition() {
            const {name} = this.$route;
            const isWork = name !== 'work' || name !== 'work-id';
            const delay = isWork ? 500 : 0;
            setTimeout(() => this.updateMarkerPosition(), delay);
        }
    },
    mounted () {
        this.addEvents();
        this.initMarkerPosition();
    },
    destroyed () {
        window.removeEventListener('resize', this.updateMarkerPosition)
    }
}

function animateIn(event) {
    const { 
        target, 
        propertyName
    } = event;

    if (propertyName === 'transform') {
        const marker = target.firstElementChild;
        const scaleTo = parseFloat(getComputedStyle(marker).getPropertyValue('--scale'));
        
        new TimelineMax()
        .set(target, { opacity: 1 })
        .fromTo(marker, 1.5, {
            scale: 0,
        }, {
            scale: scaleTo,
            ease: Back.easeOut.config(3),
            onComplete: () => {
                target.removeEventListener('transitionend', animateIn);
                marker.style = null;
            }
        });
    }
}

</script>

<style lang='scss'>

@import '../styles/mixins';
@import '../styles/vars';

.nav-marker {
    position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
	transform-origin: 50% 50%;
    transition: 
        transform 0.6s cubic-bezier(1, 0, 0, 1) 0s,
        width 0.6s cubic-bezier(1, 0, 0, 1) 0s;
    z-index: 0;
}

.nav-marker i.marker {
    --scale: 1.5;
    display: block;
    width: 100%;
    background: var(--theme-gradient);
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 100px;
    overflow: visible;
    transform: scale(var(--scale));
    transition: all 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95); // in-out-sine
}

.app-navigation .link {
    transition: all 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95); // in-out-sine
}

@media (hover: hover) {
    .link.router-link-exact-active:hover {
        color: var(--color-primary-medium);
        ~ .nav-marker .marker {
            fill: hsl(var(--color-primary), 92%);
            transform: scale(1.75);
        }
    }
}
</style>