import url from "@/utils/url";

export default {
  id: "going-to-space",
  title: "Going To Space",
  subtitle: "Content & Storytelling",
  projectUrl: "https://goingtospace.com",
  color: {
    highlight: "hsl(229, 15%, 90%)",
    light: "hsl(229, 15%, 80%)",
    dark: "hsl(228, 20%, 19%)",
    gradient:
      "linear-gradient(45deg, hsl(265, 100%, 80%), hsl(201, 100%, 50%))",
  },
  thumb: {
    src: url("gts/gts-thumb.webp"),
    width: 500,
    height: 500,
  },
  hero: {
    src: url("gts/gts-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "Launch a splash page for a space community of people who believe in a better future. I was tasked with desgining and developing a sign-up page, to collect e-mail subscriptions.",
    },
    {
      title: "Roles",
      list: [
        "Creative Direction",
        "UX/UI",
        "Front-end Development",
        "Back-end Development",
      ],
    },
    {
      title: "Agency",
      text: "Magical Threads",
    },
  ],
  breakdown: [
    {
      media: {
        images: [
          {
            src: url("gts/gts-style.webp"),
            alt: "Style Guide",
          },
        ],
      },
      layout: "layout-full",
    },
    {
      text: {
        title: "Page Features",
        summary:
          'The design is a space theme, single page layout, which features a quiz and illustrations that animate. The page collects email subscriptions by answering a "space quiz". Each answer the user selects will render unique artworks. Answers are then sent to a database.',
      },
      media: {
        images: [
          {
            src: url("gts/gts-splash.webp"),
            alt: "Single Page Design ",
          },
        ],
      },
      layout: "layout-full",
      style: {
        padding: "0 0 calc(var(--gutted) * 2)",
      },
    },
    {
      layout: "layout-ipad",
      bg: true,
      text: {
        title: "Tech Stack",
        summary:
          "Page was built with Nuxt.js as a static rendered site. Express.js is serving the static assets and handling API requests for the email subscriptions. Site is hosted on Digital Ocean on an Ubuntu virtual machine, with NGINX doing the reverse proxy work.",
      },
      media: {
        videos: [
          {
            deviceType: "iPad",
            placeholder: url("gts/gts-ipad-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/gts%2Fgts-ipad.mp4?alt=media&token=29a0c17d-f88d-48de-aefd-69c70ce89813",
            alt: "Desktop Site",
          },
        ],
      },
    },
    {
      text: {
        subtitle: "Mobile Designs",
        summary:
          "The site is fully responsive, from desktop down to the smallest mobile phones.",
      },
      media: [
        { src: url("gts/gts-mobile-1.webp") },
        { src: url("gts/gts-mobile-2.webp") },
        { src: url("gts/gts-mobile-3.webp") },
      ],
      layout: "layout-three-col",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
