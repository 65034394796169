import url from "@/utils/url";

export default {
  id: "ruthie-mario-wedding",
  title: "Ruthie & Mario’s Wedding",
  subtitle: "Personal Wedding",
  projectUrl: "https://ruthie-mario.love",
  color: {
    highlight: "hsl(48, 30%, 86%)",
    light: "hsl(48, 30%, 92%)",
    dark: "#001f33",
    gradient: "linear-gradient(45deg, hsl(48, 30%, 80%), hsl(48, 30%, 96%))",
  },
  thumb: {
    src: url("rm/rm-thumb.webp"),
    width: 500,
    height: 500,
  },
  hero: {
    src: url("rm/rm-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "This was my personal wedding website, that involved designing the landing page, development and invitation design.",
    },
    {
      title: "Roles",
      list: ["Web Design", "Front-end Development", "Print Design"],
    },
    {
      title: "Task",
      text: `Design and develop my personal wedding's website and wedding invitations.`,
    },
  ],
  breakdown: [
    {
      text: {
        title: "Web Design",
        summary: `This is the my wedding landing page that was inspired by an Art Deco style from the 1920's mixed with a contemporary flare.`,
      },
      media: {
        images: [
          {
            src: url("rm/rm-site-1.webp"),
            alt: "Wedding Landing Page",
          },
        ],
      },
      layout: "layout-hero",
    },
    {
      media: {
        images: [
          { src: url("rm/rm-site-2.webp") },
          { src: url("rm/rm-site-3.webp") },
          { src: url("rm/rm-site-4.webp") },
        ],
        videos: [
          {
            placeholder: url("rm/rm-mobile-placeholder.webp"),
            deviceType: "iphone",
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/rm%2Frm-mobile.mp4?alt=media&token=10e10518-05e2-40c0-be6c-67075f5b196b",
          },
        ],
      },
      layout: "webpage-body",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
    {
      media: [
        {
          src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/rm%2Frm-glass-toast.mp4?alt=media&token=81a35cca-d64d-49a4-beda-b442ff07d5dc",
          autoplay: true,
          placeholder: url("rm/rm-glass-toast-placeholder.webp"),
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/rm%2Frm-monogram.mp4?alt=media&token=7f632f8e-46bd-4e4a-b04c-1254e9bf84c2",
          autoplay: true,
          placeholder: url("rm/rm-monogram-placeholder.webp"),
        },
        {
          src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/rm%2Frm-wedding-rings.mp4?alt=media&token=5ea45088-c206-44c9-90c7-d41a0e0d1ec9",
          autoplay: true,
          placeholder: url("rm/rm-wedding-rings-placeholder.webp"),
        },
      ],
      layout: "layout-three-col",
    },
    {
      text: {
        title: "Wedding Invitations",
      },
      media: {
        images: [
          { src: url("rm/rm-invite-1.webp"), alt: " " },
          { src: url("rm/rm-invite-2.webp"), alt: " " },
        ],
      },
      layout: "layout-full",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
