<template>
  <main class="work">
    <section id="hero-section">
      <ParallaxContainer tag="header" class="work-header">
        <ParallaxElement
          :factor="heroParallaxFactor"
          tag="h1"
          :compensate="false"
          class="font-weight-black"
        >
          <span class="line"
            ><span class="word">I’m </span> <span class="word">a </span></span
          >
          <br />
          <span class="line"
            ><span class="word">multi</span><span class="hyphen word">-</span
            ><span class="word">disciplinary </span></span
          >
          <br />
          <span class="line"
            ><span class="word">developer </span> <Ampersand class="word" />
            <span class="word"> designer, </span></span
          >
          <br />
          <span class="line"
            ><a
              class="font-outline-inverse link-work"
              href="#work-grid-section"
              @click="goToWork"
              data-title="view my works"
              ><span class="word">view </span> <span class="word">my </span>
              <span class="word">works </span>
            </a>
            <span class="word">or </span>
          </span>
          <br />
          <span class="line"
            ><router-link
              class="font-outline-inverse link-about"
              to="/about"
              data-title="see my capabilities."
              ><span class="word">see </span> <span class="word">my </span>
              <span class="word">capabilities.</span></router-link
            ></span
          >
        </ParallaxElement>
      </ParallaxContainer>
    </section>

    <WorkGrid ref="workGrid" />

    <ParallaxContainer tag="section" id="about-section">
      <header class="work-header">
        <h3>About</h3>
        <div class="work-text">
          <p class="font-size-large">{{ $store.getters.about.shortBio }}</p>
          <AppButton icon="arrow-right">
            <router-link
              to="/about"
              slot="outer"
              aria-label="Learn More About Mario Luevanos"
            />
            See Capabilities
          </AppButton>
        </div>
      </header>
    </ParallaxContainer>
  </main>
</template>

<script>
// Components
import AppButton from "@/components/AppButton.vue";
import WorkGrid from "@/components/WorkGrid.vue";
import ParallaxContainer from "@/components/ParallaxContainer";
import ParallaxElement from "@/components/ParallaxElement";
import Ampersand from "@/components/Ampersand";

// Transitions
import onLeave from "@/transitions/onLeave";

// Utils
import scrollTo from "@/utils/scrollTo";

export default {
  name: "work",
  components: {
    AppButton,
    Ampersand,
    WorkGrid,
    ParallaxContainer,
    ParallaxElement,
  },
  beforeRouteLeave(to, from, next) {
    onLeave.call(this, { to, from, next });
  },
  computed: {
    heroParallaxFactor() {
      return window.innerWidth >= 768 ? 1 : 0.3;
    },
  },
  methods: {
    goToWork(event) {
      event.preventDefault();
      scrollTo({
        yPos: this.$refs.workGrid.$el.offsetTop - 20,
        duration: 0.6,
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/mixins";
@import "../styles/vars";

#page.work section {
  padding-left: vw(var(--gutter));
  padding-right: vw(var(--gutter));
  padding-bottom: vw(var(--gutter));
  width: 100%;

  .work-header .font-size-huge {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-title {
    color: var(--theme-dark);
    @include bp(mobile) {
      @include base-font(h4);
    }
  }
}

// - Hero
// -------------------------------------------

#hero-section {
  padding-top: vw(var(--gutter));
  padding-bottom: vw(var(--gutter));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80vh;
  h1 {
    width: 100%;
    text-transform: uppercase;
    line-height: 1;
    position: relative;
    @include bp(mobile) {
      width: auto;
      @include base-font(h2);
    }
    br {
      @include bp(mobile) {
        display: none;
      }
    }
  }
  h1 > div {
    overflow: hidden;
    position: relative;
  }
  h1 .hyphen {
    display: none;
    @include bp(mobile) {
      display: inline-block;
    }
  }
  h1 .ampersand {
    fill: var(--theme-highlight);
    height: 0.75em;
    width: auto;
    display: inline-block;
    transform: scale(1.35);
  }
  .font-outline {
    display: inline;
    cursor: pointer;
    white-space: nowrap;
    @include bp(mobile) {
      white-space: normal;
    }
  }
}

#archives-section {
  position: relative;
  z-index: 3;
}

#page.work #about-section {
  background: white;
  .work-header {
    align-items: flex-start;
    padding: vw(var(--gutter)) 0;
    @include bp(tablet) {
      flex-direction: column;
      padding: vw(calc(var(--gutter) * 2)) 0;
    }
    h3 {
      min-width: vw(190);
      @include bp(tablet) {
        margin-bottom: vw(calc(var(--gutter) / 2));
      }
    }
    p {
      width: 89.5%;
      margin: 0 0 vw(calc(var(--gutter) / 2));
    }
  }
  .app-button {
    float: right;
    @include bp(mobile) {
      float: none;
    }
  }
}

#page.work + #app-footer {
  background: white;
}
</style>
