import url from "@/utils/url";

export default {
  id: "zzamong-restaurant",
  title: "Zzamong Restaurant",
  subtitle: "Korean-Chinese Restaurant in LA",
  projectUrl: "http://zzamongrestaurant.com",
  color: {
    highlight: "hsla(37, 33%, 90%, 1)",
    light: "hsl(41, 15%, 80%)",
    dark: "hsl(35, 100%, 7%)",
    gradient:
      "linear-gradient(45deg, hsla(37, 33%, 80%, 1), hsla(37, 33%, 95%, 1))",
  },
  thumb: {
    src: url("zzamong/zzamong-thumb.webp"),
    width: 800,
    height: 1280,
  },
  hero: {
    src: url("zzamong/zzamong-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "Zzzamong Restaurant is popular Korean-Chinese Food Restaurant, located in Los Angeles. Known for their Jjajangmyun Noodles.",
    },
    {
      title: "Roles",
      list: ["Web Design", "Back-end Development", "Front-end Development"],
    },
    {
      title: "Task",
      text: "The task was to redesign the site and develop a CMS to manage the menu and prices.",
    },
  ],
  breakdown: [
    {
      text: {
        title: "Web Design",
        summary:
          "Site was design to easily access the menu and place a food order. Instead of linking the menu to a PDF in another page, the menu items are visible and selectable within a click. <br> <br> The important information that a user seeks is at the top of the page and the adjacent social profile links.",
      },
      media: {
        images: [
          {
            src: url("zzamong/zzamong-site-1.webp"),
            alt: "Single Page Restaurant",
          },
        ],
      },
      layout: "layout-hero",
    },
    {
      media: {
        images: [
          { src: url("zzamong/zzamong-site-2.webp") },
          { src: url("zzamong/zzamong-site-3.webp") },
          { src: url("zzamong/zzamong-site-4.webp") },
          { src: url("zzamong/zzamong-site-5.webp") },
          { src: url("zzamong/zzamong-site-6.webp") },
          { src: url("zzamong/zzamong-site-7.webp") },
        ],
        videos: [
          {
            deviceType: "iphone",
            placeholder: url("zzamong/zzamong-mobile-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/zzamong%2Fzzamong-mobile.mp4?alt=media&token=dbd5beeb-e50f-42e6-992c-3135884de0c4",
          },
        ],
      },
      layout: "webpage-body",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
    {
      text: {
        title: "Content Manangement System",
        summary:
          "With a CMS the client is able to create, update and delete menu items from the admin site. The CMS is built with Node.js and is paired with Express.js for the creation of the API. Storage for media and restaurant menu is saved in Firebase Firestore.",
      },
      media: {
        images: [
          {
            src: url("zzamong/zzamong-admin-1.webp"),
            alt: "",
          },
          {
            src: url("zzamong/zzamong-admin-2.webp"),
            alt: "",
          },
          {
            src: url("zzamong/zzamong-admin-3.webp"),
            alt: "",
          },
          {
            src: url("zzamong/zzamong-admin-4.webp"),
            alt: "",
          },
        ],
      },
      layout: "layout-quad",
    },
    {
      layout: "layout-ipad",
      bg: true,
      text: {
        title: "Front-end Development",
        summary:
          "The restaurant site is only one page, resulting in the development phase of the client-side to about a week. The output is statically generated HTML that fetches the menu and site's content per initial load.",
      },
      media: {
        videos: [
          {
            deviceType: "ipad",
            placeholder: url("zzamong/zzamong-ipad-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/zzamong%2Fzzamong-ipad.mp4?alt=media&token=45a1be1a-642f-4c54-a3c6-0a91827d039b",
            alt: "",
          },
        ],
      },
      style: {
        marginTop: "var(--gutted)",
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
