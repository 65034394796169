import url from "@/utils/url";

export default {
  id: "byte",
  title: "Byte.com",
  subtitle: "Marketing with E-Commerce",
  projectUrl: "https://byte.com",
  color: {
    highlight: "#EDF7F4",
    highlightText: "#000",
    light: "#FFF",
    dark: "#000",
    gradient: "linear-gradient(45deg, #b2ded1, #d1ebe3)",
  },
  thumb: {
    src: url("byte/byte-thumb.webp"),
    width: 500,
    height: 500,
  },
  hero: {
    src: url("byte/byte-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "Byte is an at-home dental aligner company. I was part of a large team of engineers and focused on the top of the sales funnel.",
    },
    {
      title: "Roles",
      list: ["Senior Web Engineer", "Library Author", "UX Developer"],
    },
    {
      title: "Tasks",
      text: "Maintained and developed features that included SaaS from Shopify and Contentful.",
    },
  ],
  breakdown: [
    {
      layout: "layout-ipad",
      bg: true,
      text: {
        title: "Search Functionality",
        summary:
          'Designed and developed search functionaly for "Frequently Asked Questions" with React.js and a Contentful CMS.',
      },
      media: {
        videos: [
          {
            deviceType: false,
            placeholder: url("mt/mt-desktop-placeholder.webp"),
            src: url("byte/byte-faqs.webm"),
            alt: "FAQ Page",
          },
        ],
        style: {
          paddingLeft: "var(--gutted)",
          paddingRight: "var(--gutted)",
          marginBottom: "var(--gutted)",
        },
      },
    },
    {
      media: {
        images: [
          [
            {
              src: url("byte/byte-faqs-mobile.webm"),
              alt: "Mobile Display",
              style: {
                paddingLeft: "0",
                marginLeft: "var(--gutted)",
                marginBottom: "var(--gutted)",
                width: "calc(100% - var(--gutted))",
              },
            },
          ],
          [
            {
              src: url("byte/byte-faqs-mobile-code.svg"),
              alt: "Search Logic",
              style: {
                paddingLeft: "var(--gutted)",
                marginBottom: "var(--gutted)",
              },
            },
          ],
        ],
      },
      layout: "layout-two-col",
    },
  ],
};
