<template>
  <div id="app">
    <AppTransitionDiv />
    <AppNavigation />
    <router-view id="page" />
    <AppFooter />
  </div>
</template>

<script>
// Components
import AppTransitionDiv from "@/components/AppTransitionDiv";
import AppLogo from "@/components/AppLogo";
import AppNavigation from "@/components/AppNavigation";
import AppFooter from "@/components/AppFooter.vue";

// Utils
import debounce from "@/utils/debounce";
import setMetaThemeColor from "@/utils/setMetaThemeColor";

// Libs
import FontFaceObserver from "fontfaceobserver";

// Transitions
import onEnter from "@/transitions/onEnter";

export default {
  name: "app",
  components: {
    AppTransitionDiv,
    AppLogo,
    AppNavigation,
    AppFooter,
  },
  watch: {
    $route(to, from) {
      // Exclude these entries from the onEnter call
      if (["admin", "blog"].indexOf(to.name) === -1)
        this.$nextTick(this.onEnter);

      // Set the theme if not work-id
      if (to.name !== "work-id") this.$store.dispatch("updateTheme");

      // Sets the <meta/> tag color theme
      setMetaThemeColor(this.$store.getters.themeColor.highlight);
    },
  },
  methods: {
    onEnter() {
      const pageComponent = this.findPageComponent();
      onEnter.call(pageComponent);
    },
    findPageComponent() {
      return this.$children.find((child) => child.$attrs.id === "page");
    },
    loadFonts() {
      Promise.all([
        new FontFaceObserver("MenaGrotesk-Regular"),
        new FontFaceObserver("MenaGrotesk-Bold"),
        new FontFaceObserver("MenaGrotesk-Black"),
      ]).then(() => document.documentElement.classList.add("fonts-loaded"));
    },
  },
  mounted() {
    this.loadFonts();
  },
};
</script>
<style lang="scss">
@import "./styles/global.scss";

#app {
  width: 100vw;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

#page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
