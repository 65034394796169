<template >
<svg viewBox="0 0 50 50" class='app-logo'>
    <path d="M24.5,38.5c-1.7,2.8-4.3,6-8.8,10.2l-0.4-0.4l1.6-1.5c-4-3.7-10-8.9-16.1-6.5l-0.3-0.4
    c0.6-0.3,4.7-4.4,5.2-4.8c3.6,0,9.3-1.5,9-5.9C14.2,23,6.5,16,1.7,12.8c-0.1-0.1-0.7-0.3-1,0.1l-0.3-0.1C2.5,8.4,5.6,4.9,9.9,0.6
    l0.4,0.4L8.5,2.9c3.3,2.8,6.1,5.8,8.1,8.8c3.3-3.8,7.4-7.7,9.2-11L26.4,1l-1,1.5c2.5,1,5.4,1.8,8.4,2.6c-0.4,1.2-0.5,2.4-1,3.6
    c3.6-2.6,6.3-4.5,9.4-8l0.4,0.4l-1.3,1.5c1.2,1.4,5.3,2.6,7.7,3.1c-1.4,2-2.4,8.2-2.4,11.1v20.5c0,1.1,0.4,1.5,0.9,2l2,1.8
    c-2.1,2.9-3.8,5.8-5.1,8.3L44,49.1l1.3-2.4c-1.1-1-2.9-2.6-5.4-4c0.4-0.6,0.8-2,0.8-4.9V25.2c-3,0.7-4.9,2.6-7.8,4.5l-0.1,8.7
    c0,1.4,1.3,2.9,2.7,3.5c-2.4,2-4.5,4.3-6.6,6.8l-0.6-0.4l1.4-1.6l-5.1-5.2c0.9-1,2.2-2.2,2.2-3.7l0.3-27.3c-2-1.2-4.6-1.9-6.6-2.2
    c-2.8,2.2-2.7,5.2-1.7,7.1c3.8,7.2,2,14.7-7,19.4c3.4,1.5,7.3,4,10,6.2c0.8-1.1,1.5-2,2-2.9L24.5,38.5z M3.1,9.3
    C2.7,9.9,2.3,10.5,2.1,11c5.1,4,13.1,11.1,13.4,18.4C18.6,22.8,8.8,13.5,3.1,9.3z M40.6,20.3c0-4.7-0.1-6,2.6-9.3
    c-2.4-0.8-5.5-1.9-7.8-3.3c-1.7,1.5-2.6,2.9-2.6,4.3v14L40.6,20.3z M40.6,21.7c-2.7,1.3-5.1,2.9-7.7,5v1.9c2.6-1.5,4.9-3,7.7-4.5
    V21.7z"/>
</svg>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    name: 'app-logo'
}
</script>

<style lang='scss' scoped>

@import '../styles/mixins';

.app-logo {
    max-width: 2rem;
    width: 100%;
    height: auto;
    &.dark {
        fill: black;
    }
    &.light {
        fill: white;
    }
    &.gray {
        fill: var(--color-font);
    }
}

</style>
