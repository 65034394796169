import url from "@/utils/url";

export default {
  id: "magical-threads",
  title: "Magical Threads",
  subtitle: "Marketing & Creative Agency",
  projectUrl: "https://magicalthreads.co",
  color: {
    highlight: "hsl(0, 0%, 97%)",
    light: "hsl(0, 0%, 70%)",
    dark: "hsl(0, 0%, 5%)",
    gradient: "linear-gradient(45deg, hsl(0, 0%, 80%), hsl(0, 0%, 50%))",
  },
  thumb: {
    src: url("mt/mt-thumb.webp"),
    width: 500,
    height: 500,
  },
  hero: {
    src: url("mt/mt-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "Design a brand indentity, design and launch a presentation style webpage for an Agency.",
    },
    {
      title: "Roles",
      list: [
        "Logo Design",
        "Creative Direction",
        "UX/UI",
        "Front-end Development",
      ],
    },
    {
      title: "Agency",
      text: "Magical Threads",
    },
  ],
  breakdown: [
    {
      layout: "layout-half",
      text: {
        subtitle: "Logo Design Concepts",
        summary:
          'First task was to design a brand indentity for Magical Threads. The idea was to combine the concept of a thread and the letter "M". What I propsed is to the left.',
      },
      media: {
        videos: [
          {
            placeholder: url("mt/mt-logos-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/mt%2Fmt-logos.mp4?alt=media&token=00c23907-6bf4-41a8-983c-035af5591e4a",
            alt: "",
          },
        ],
        style: {
          "mix-blend-mode": "difference",
        },
      },
    },
    {
      layout: "layout-half reversed",
      text: {
        subtitle: "Final Logo",
        summary:
          'The final logo chosen by Magical Threads. The circular letter "M" logo mark is paired with the Posterama typeface, which was designed by Jim Ford.',
      },
      media: {
        videos: [
          {
            placeholder: url("mt/mt-logo-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/mt%2Fmt-logo-final.mp4?alt=media&token=8ab870ff-f6b3-4d6f-9610-e62bb34e987a",
            alt: "",
          },
        ],
      },
    },
    {
      layout: "layout-ipad",
      bg: true,
      text: {
        title: "Single Page Site",
        summary:
          "The ideas was to create a unique page-design for each section. I created functionality as you would see in a slide presentation. Each slide would have a unique design. To add the animations to the 2D images, I decided to use Pixi.js to add an interesting effect. Vue.js was also uses to control the slide state.",
      },
      media: {
        videos: [
          {
            deviceType: false,
            placeholder: url("mt/mt-desktop-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/mt%2Fmt-desktop.mp4?alt=media&token=55e793dd-03df-4be4-bcf7-3ba9228703b4",
            alt: "Desktop Site",
          },
        ],
        style: {
          paddingLeft: "var(--gutted)",
          paddingRight: "var(--gutted)",
        },
      },
    },
    {
      text: {
        subtitle: "Mobile Designs",
        summary:
          "The site is fully responsive, from desktop down to the smallest mobile phones. Here are some screen shots from the mobile view.",
      },
      media: [
        { src: url("mt/mt-mobile-1.webp") },
        { src: url("mt/mt-mobile-2.webp") },
        { src: url("mt/mt-mobile-3.webp") },
        { src: url("mt/mt-mobile-4.webp") },
        { src: url("mt/mt-mobile-5.webp") },
        { src: url("mt/mt-mobile-6.webp") },
      ],
      layout: "layout-three-col",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
