export default {
  name: "parallax-container",
  provide() {
    return {
      parallaxContainer: this.data,
    };
  },
  props: {
    to: {
      type: String,
    },
    tag: {
      type: String,
      default: "div",
    },
  },
  data() {
    return {
      data: {
        height: 0,
        scrollFactor: 0,
        width: 0,
      },
    };
  },
  mounted() {
    this.calcParallax();

    // Using a requestAnimationFrame() to update
    const eventHandler = () => requestAnimationFrame(this.calcParallax);
    window.addEventListener("resize", eventHandler);
    window.addEventListener("scroll", eventHandler);

    // Remove the scroll hanlder when the
    // component is destroyed.
    this.$on("hook:destroyed", () => {
      window.removeEventListener("resize", eventHandler);
      window.removeEventListener("scroll", eventHandler);
    });
  },
  methods: {
    calcParallax() {
      const containerRect = this.$el.getBoundingClientRect();

      this.data.height = containerRect.height;
      this.data.width = containerRect.width;

      const viewportOffsetTop = containerRect.top;
      const viewportOffsetBottom = window.innerHeight - viewportOffsetTop;

      this.data.scrollFactor =
        viewportOffsetBottom / (window.innerHeight + this.data.height);
    },
    setOptions() {
      return this.to
        ? {
            on: {
              click: (event) => {
                event.preventDefault();
                this.$router.push(this.to);
              },
            },
            attrs: {
              href: this.to,
            },
          }
        : {};
    },
  },
  render(h) {
    const { tag, $slots } = this;
    return h(tag, this.setOptions(), $slots.default);
  },
};
