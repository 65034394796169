import { TweenMax, TimelineMax, Expo } from "gsap";
import "@/vendor/gsap.SplitText";

export default function onEnter() {
  const { $el } = this;
  const isWork = $el.classList.contains("work");
  const isAbout = $el.classList.contains("about");

  if (isWork) {
    workAnimations.call(this);
  } else if (isAbout) {
    aboutAnimations.call(this);
  } else {
    workIdAnimations.call(this);
  }

  const appFooter = document.getElementById("app-footer");

  TweenMax.to(appFooter, 1, {
    autoAlpha: 1,
    clipPath: isWork ? "inset(0 0 0 0%)" : "inset(0 0% 0 0)",
    ease: Expo.easeIn,
  });
}

function workIdAnimations() {
  const rootEl = this.$el;
  const title = rootEl.querySelector(".work-title");
  const button = rootEl.querySelector(".work-aside");
  const image = rootEl.querySelector(".work-poster-image") || {};
  const thumb = rootEl.querySelector(".work-thumb-image") || {};
  const from = this.$store.getters.routeFrom;
  const fromId = from.name === "work-id";
  const fromDir = fromId ? "inset(0 0 0 100%)" : "inset(0 100% 0 0)";
  const toDir = fromId ? "inset(0 0 0 0%)" : "inset(0 0% 0 0)";
  const heroChildren = [title, thumb, image, button];
  const staggerDelay = 0.15;

  return new TimelineMax()
    .fromTo(
      rootEl,
      2,
      {
        autoAlpha: 0,
        x: 0,
      },
      {
        autoAlpha: 1,
        x: 0,
        ease: Expo.easeOut,
      },
      0
    )

    .add("first", 0.3)
    .staggerFromTo(
      heroChildren,
      1.7,
      {
        x: fromId ? 50 : -50,
      },
      {
        x: 0,
        ease: Expo.easeOut,
      },
      staggerDelay,
      "first"
    )
    .staggerFromTo(
      heroChildren,
      1,
      {
        clipPath: fromDir,
      },
      {
        clipPath: toDir,
        ease: Expo.easeOut,
      },
      staggerDelay,
      "first"
    )
    .staggerFromTo(
      heroChildren,
      0.6,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: Expo.easeOut,
      },
      staggerDelay,
      "first"
    );
}

function workAnimations() {
  const rootEl = this.$el;
  const isDesktop = window.innerWidth > 768;
  const selector = isDesktop ? ".line" : ".word";
  const letters = Array.from(rootEl.querySelectorAll(selector));
  const hero = rootEl.querySelector("#hero-section");
  const verticalText = rootEl.querySelector(".vertical-text");
  const workGrid = rootEl.querySelector(".work-grid");
  const staggerDelay = isDesktop ? 0.1 : 0.025;

  return new TimelineMax({
    onComplete() {
      letters.forEach((l) => (l.style = null));
    },
  })
    .fromTo(
      hero,
      1,
      {
        autoAlpha: 0,
        x: 150,
      },
      {
        autoAlpha: 1,
        x: 0,
        ease: Power3.easeOut,
      },
      0
    )

    .staggerFromTo(
      letters,
      1,
      {
        x: 150,
        opacity: 0,
        clipPath: "inset(0 0 0 100%)",
      },
      {
        x: 0,
        opacity: 1,
        clipPath: "inset(0 0 0 0%)",
        ease: Expo.easeOut,
      },
      staggerDelay,
      "-=0.7"
    )

    .staggerFromTo(
      [verticalText, ...workGrid.children],
      1,
      {
        autoAlpha: 0,
        scale: 0.8,
        x: 60,
        transformOrigin: "100% 0",
      },
      {
        autoAlpha: 1,
        scale: 1,
        x: 0,
        ease: Power3.easeOut,
      },
      0.1,
      "-=0.9"
    );
}

function aboutAnimations() {
  const rootEl = this.$el;
  const from = this.$store.getters.routeFrom;
  const fromId = from.name === "work-id";
  const heroIntro = rootEl.querySelector(".hero-intro");
  const bg = rootEl.querySelector(".bg");
  const st = new SplitText(heroIntro, { type: "lines" });
  const sections = rootEl.querySelectorAll("section:not(.hero)");

  // A quick way to remove SplitText
  const onResize = (event) => {
    st.revert();
    window.removeEventListener("resize", onResize);
  };

  window.addEventListener("resize", onResize, false);

  return new TimelineMax()
    .fromTo(
      bg,
      2,
      {
        scaleX: 0,
        transformOrigin: fromId ? "100% 50%" : "0% 50%",
      },
      {
        scaleX: 1,
        ease: Expo.easeOut,
      },
      0
    )
    .staggerFromTo(
      st.lines,
      1,
      {
        x: fromId ? 25 : -25,
        opacity: 0,
        clipPath: fromId ? "inset(0 0 0 100%)" : "inset(0 100% 0 0)",
      },
      {
        x: 0,
        opacity: 1,
        clipPath: fromId ? "inset(0 0 0 0%)" : "inset(0 0% 0 0)",
        ease: Expo.easeOut,
        onComplete: () => st.lines.forEach((line) => (line.style = null)),
      },
      0.1,
      "-=1.7"
    )
    .from(
      sections,
      1,
      {
        x: fromId ? 150 : -150,
        scale: 0.9,
        autoAlpha: 0,
        clipPath: fromId ? "inset(0 0 0 100%)" : "inset(0 100% 0 0)",
        transformOrigin: fromId ? "100% 0%" : "0% 0%",
        ease: Expo.easeOut,
      },
      "-=1.3"
    );
}
