import url from "@/utils/url";

export default {
  id: "crews-1972",
  title: "Crews 1972",
  subtitle: "Concession & Hospitality",
  projectUrl: "https://crews1972.com",
  color: {
    highlight: "#f8f1e7",
    light: "#ebe4da",
    dark: "#331E00",
    gradient: "linear-gradient(45deg, #e4d1b8, #f8f1e7)",
  },
  thumb: {
    src: url("crews/crews-1972-thumb.webp"),
    width: 500,
    height: 500,
  },
  hero: {
    src: url("crews/crews-1972-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "Crews 1972 is a family-owned business, evolving from a single airport greeting card shop in Newark into a national hospitality brand. The task was to redesign and develop their website and corporate assets with a fresh new look.",
    },
    {
      title: "Roles",
      list: [
        "UX Design",
        "Front-end Development",
        "Corporate Design",
        "Creative Direction",
      ],
    },
    {
      title: "Agency",
      text: "Magical Threads",
    },
  ],
  breakdown: [
    {
      text: {
        title: "UX Design",
        summary:
          "I kicked off the project with UX wire frames and initial prototypes. I began by the process by mapping the old site and discovering areas that needed improvements. Pages received improved interface designs, by  identifying user personas, and navigational flows. Important elements were emphasized with clear call to actions.",
      },
      media: {
        images: [
          {
            src: url("crews/crews-1972-wireframes.webp"),
            alt: "",
          },
        ],
      },
      layout: "layout-half",
    },
    {
      text: {
        title: "Web Development",
        summary:
          "After the designs approved by the client, I began the development phase. Vue.js is behind the front-end. Vuex for state management and Firebase Cloud Functions for server side rendering.<br><br>After site was completed, the new site was deployed with Firebase Hosting.",
      },
      media: {
        videos: [
          {
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/crews%2Fcrews-mobile.mp4?alt=media&token=cb6a2ec2-6984-4702-a1cc-22465734c483",
            deviceType: "iPhone",
            alt: "Mobile Pages",
            placeholder: url("crews/crews-mobile-placeholder.webp"),
          },
          {
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/crews%2Fcrews-interactions.mp4?alt=media&token=72fa517c-9ad9-4110-b7d7-d4825ff3087d",
            deviceType: "iPhone",
            alt: "UI Interactions",
            placeholder: url("crews/crews-interactions-placeholder.webp"),
          },
        ],
      },
      layout: "layout-mobile-deuce",
      style: {
        marginTop: "var(--gutted)",
      },
    },
    {
      layout: "layout-ipad",
      bg: true,
      text: {
        summary:
          "The site is responsive to all device sizes. Style sheets for mobile phones and tablets are part of every project. Since the introduction to CSS variables, the file size of authored styles have been reduced by a significant amount.",
      },
      media: {
        videos: [
          {
            autoplay: true,
            deviceType: "ipad",
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/crews%2Fcrews-ipad.mp4?alt=media&token=faa5a2d2-2f39-48b4-98d9-f5b9b267ec2d",
            placeholder: url("crews/crews-ipad-placeholder.webp"),
            alt: "Desktop Site",
          },
        ],
      },
    },
    {
      text: {
        summary:
          "The back-end is powered by Wordpress API, utilizing the headless content management system. With Wordpress CMS, the client is able to customize the company map.<br><br>After an initial API request, each pin location is rendered with the Google Maps API.",
      },
      media: {
        images: [
          {
            src: url("crews/crews-1972-codemap.webp"),
            alt: "Locations Page",
          },
        ],
      },
      layout: "layout-half reversed",
      style: {
        marginTop: "var(--gutted)",
      },
    },
    {
      text: {
        title: "Print Design",
        summary:
          "Other tasks included the designing a booklet and RFP’s for their corporate design collateral. Typography was placed with hierarchy, and layout was arranged in a scannable fashion.<br><br> By this time, a design system had been developed and creating these pages was done quickly.",
      },
      media: {
        images: [
          {
            src: url("crews/crews-1972-booklet.webp"),
            alt: " ",
          },
        ],
      },
      layout: "layout-full",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
