import defaultTheme from "@/store/defaultTheme";

export default function updateTransitionTheme(to) {
  const transDiv = document.getElementById("app-transition-div");
  const toProject = to.name === "work-id";
  const project = this.$store.getters.projects.find(
    (p) => p.id === to.params.id
  );

  if (!project) return;
  const colors = toProject ? project.color : defaultTheme();
  const { light, dark, highlight } = colors;
  transDiv.style.setProperty("--theme-light", light);
  transDiv.style.setProperty("--theme-dark", dark);
  transDiv.style.setProperty("--theme-highlight", highlight);
}
