import url from "@/utils/url";

export default {
  id: "deviant-art-media-kit",
  title: "DeviantArt Media Kit",
  subtitle: "Largest Online Art Community",
  projectUrl: "https://deviantartads.com",
  color: {
    highlight: "hsl(140, 15%, 85%)",
    light: "hsla(146, 15%, 90%, 1)",
    dark: "hsl(137, 100%, 7%)",
    gradient: "linear-gradient(45deg, hsl(140, 15%, 75%), hsl(180, 15%, 90%))",
  },
  thumb: {
    src: url("da/da-media-kit-thumb.webp"),
    width: 500,
    height: 500,
  },
  hero: {
    src: url("da/da-media-kit-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "DeviantArt is the biggest art community online, that primarily focuses on the users and tries to make their experience the best possible for an artist at any level.",
    },
    {
      title: "Roles",
      list: ["Web Design", "Front-end Development"],
    },
    {
      title: "Task",
      text: "Desgin and develop a single page marketing, landing page for the Advertising department.",
    },
  ],
  breakdown: [
    {
      text: {
        title: "Web Design",
        summary:
          "A single page for DeviantArt marketing. Serves as a marketing tool for advertising sales at DeviantArt. Stylized and inspired by the new re-brand.",
      },
      media: {
        images: [
          {
            src: url("da/da-media-kit-site-1.webp"),
            alt: "Single Page Media Kit",
          },
        ],
      },
      layout: "layout-hero",
    },
    {
      media: {
        images: [
          { src: url("da/da-media-kit-site-2.webp") },
          { src: url("da/da-media-kit-site-3.webp") },
          { src: url("da/da-media-kit-site-4.webp") },
          { src: url("da/da-media-kit-site-5.webp") },
          { src: url("da/da-media-kit-site-6.webp") },
          { src: url("da/da-media-kit-site-7.webp") },
          { src: url("da/da-media-kit-site-8.webp") },
        ],
        videos: [
          {
            placeholder: url("da/da-media-kit-mobile-placeholder.webp"),
            deviceType: "iphone",
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/da%2Fda-media-kit-mobile.mp4?alt=media&token=4def3da3-4231-4a6d-b33d-02611d6137f2",
          },
        ],
      },
      layout: "webpage-body",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
    {
      layout: "layout-ipad",
      bg: true,
      text: {
        title: "Front-end Development",
        summary:
          "This site was built with HTML, CSS and JavaScript, no frameworks, only Require.js to help tidy code by creating modules before ES6. Back when we used simpler build tools.",
      },
      media: {
        videos: [
          {
            deviceType: "iPad",
            placeholder: url("da/da-media-kit-ipad-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/da%2Fda-media-kit-ipad.mp4?alt=media&token=cf762d8b-5471-4e05-835f-8ea1c2b652ac",
            alt: "Desktop Site",
          },
        ],
      },
      // style: {
      //     marginBottom: 'var(--gutted)'
      // }
    },
    /// Typecrop
    {
      text: {
        title: "DeviantArt Typography (Typecrop.js)",
        summary: `DeviantArt's typeface is called Calibre (not Calibri) by a type foundry called Klim. They use caps whenever possible and give it a custom treatment by cutting off the first and last letters of words and/or sentences with the diagonal angle of our logo. The task was also to automate the cropping of DeviantArt's typeface.`,
      },
      media: {
        images: [
          {
            src: url("typecrop/typecrop-site.webp"),
            alt: "Landing Page",
            style: {
              marginBottom: "var(--gutted)",
            },
          },
        ],
      },
      layout: "layout-full",
    },
    {
      media: {
        images: [
          {
            src: url("typecrop/typecrop-poster.webp"),
            alt: "Example Usage",
            style: {
              marginBottom: "var(--gutted)",
            },
          },
        ],
      },
      layout: "layout-full",
    },
  ],
};
