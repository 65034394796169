import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ga from "./ga";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    ga();
  },
}).$mount("#app");
