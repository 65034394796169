<template>
    <footer id='app-footer' style='opacity: 0'>
        <div class='bg' style='transition: none'/>
        <AppLogo @click.native='onLogoClick'/>
        <div class='links'>
            <a 
                class='link font-weight-bold' 
                :href='link.href' 
                v-for='link in links' 
                :key='link.name'
            >
                {{link.name}}
            </a>
            <div class='signature font-size-small'>
                @{{getCurrentYear()}} Mario Luevanos
            </div>
        </div>
    </footer>
</template>

<script>
import AppLogo from '@/components/AppLogo.vue';
import socialLinks from '@/store/socialLinks';
import scrollTo from '@/utils/scrollTo';
import { TweenMax } from 'gsap';

export default {
    name: 'app-footer',
    components: {
        AppLogo
    },
    data() {
        return {
            links: socialLinks() 
        };
    },
    methods: {
        getCurrentYear() {
            return (new Date()).getFullYear();
        },
        onLogoClick() {
            if (this.$route.name === 'work') {
                scrollTo({ yPos: 0, duration: 1 });
            } else {
                this.$router.push({name: `work` });
            }
        }
    },
    mounted() {
        TweenMax.to(this.$el, 0.3, {
            opacity: 1,
            ease: Sine.easeOut
        });
    }
}
</script>

<style lang='scss'>

@import '../styles/mixins';
@import '../styles/vars';

#app-footer {
    color: var(--theme-highlight);
    padding: 0 vw(var(--gutter));
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 50vh;
    z-index: 1;
    overflow: hidden;
    @include bp(mobile) {
        flex-direction: row;
        align-items: flex-end;
        padding-top: vw(60);
        padding-bottom: vw(60);
    }
    .bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: var(--theme-dark);
        left: vw(315);
        z-index: -1;
        transform: translate3d(0, 0, 0);
        transition: all 0.3s ease-out;
        @include bp(mobile) {
            left: 0;
        }
    }
}

#app-footer .app-logo {
    cursor: pointer;
    fill: var(--theme-dark);
    pointer-events: all;
    z-index: 1;
    @include bp(mobile) {
        fill: var(--theme-highlight);
    }
}

#app-footer .links {
    margin: vh(50) 0;
    @include bp(mobile) {
        flex-direction: column;
        margin: 0;
        display: flex;
        align-items: flex-end;
    }
    .link {
        color: inherit;
        &:not(:last-of-type) {
            margin-right: vw(60);
            @include bp(mobile) {
                margin-right: 0;
                margin-bottom: vw(10);
            }
        }
    }
    .signature {
        margin-top: vw(40);
        text-align: right;
        opacity: 0.66;
        color: var(--theme-light);
    }
}
</style>
