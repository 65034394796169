export default function ga() {
  function loadGTM() {
    const gtmUrl = "https://www.googletagmanager.com/gtag/js?id=UA-4340131-1";
    const gtmScript = document.createElement("script");
    gtmScript.setAttribute("async", true);
    gtmScript.setAttribute("src", gtmUrl);
    document.body.appendChild(gtmScript);

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", "UA-4340131-1");
  }
  window.addEventListener("load", loadGTM, false);
}
