<template>
  <button class="app-button" :class="{ 'centered-text': !icon }">
    <slot name="outer" />
    <span class="app-button-text" ref="text">
      <slot />
    </span>
    <AppIcon v-if="icon" :name="icon" />
  </button>
</template>

<script>
import AppIcon from "@/components/AppIcon.vue";
import "@/vendor/gsap.SplitText";

export default {
  name: "app-button",
  components: {
    AppIcon,
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
  methods: {
    setButtonText() {
      const text = new SplitText(this.$refs.text, {
        type: "chars",
        charsClass: "char",
      });
      text.chars.forEach((char, index) => {
        char.dataset.char = char.innerText;
        char.style.transitionDelay = `${index * 0.01}s`;
      });
    },
  },
  mounted() {
    this.setButtonText();
  },
};
</script>

<style lang="scss">
@import "../styles/vars";
@import "../styles/mixins";

.app-button {
  position: relative;
  cursor: pointer;
  pointer-events: auto;
  padding: vw(15) vw(30);
  border: none;
  background: transparent;
  border-radius: 3px;
  color: var(--theme-dark);
  transition: all 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95); // in-out-sine
  outline: none;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  &.centered-text {
    display: flex;
    justify-content: center;
  }
  a {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &::before {
    content: "";
    background: var(--theme-gradient);
    border-radius: inherit;
    transition: inherit;
    @include max-area;
    pointer-events: none;
  }

  &::after {
    content: "";
    background: hsl(var(--color-primary), 92%);
    border-radius: inherit;
    transition: inherit;
    opacity: 0;
    pointer-events: none;
    @include max-area;
  }

  .app-button-text {
    position: relative;
    pointer-events: none;
    cursor: pointer;
    z-index: 1;
  }

  .app-icon {
    pointer-events: none;
    position: relative;
    margin-left: vw(10);
    transform: translateY(0%);
    transform-origin: 0 50%;
    transition: all 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95); // in-out-sine
    stroke: var(--theme-dark);
    z-index: 1;
  }
}

@media (hover: hover) {
  .app-button:hover:not([disabled]) {
    color: hsl(var(--color-primary-medium));
    &::after {
      opacity: 1;
      transform: scale(1.1);
    }

    .app-icon {
      stroke: hsl(var(--color-primary-medium));
      transform: scale(1.1) translate3d(15%, 0%, 0);
    }
  }
}

.char {
  overflow: hidden;
  position: relative;
  color: transparent;
  vertical-align: middle;
}

.char::before {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  color: var(--theme-dark);
}

.char::after {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
  color: hsl(var(--color-primary-medium));
}

// Hover Transition
// ----------------------------------

.char::before,
.char::after {
  position: absolute;
  top: 0;
  left: 0;
  content: attr(data-char);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  transition-duration: 0.25s;
  transition-delay: inherit;
}

@media (hover: hover) {
  .app-button:hover:not([disabled]) .char::before {
    transform: translate3d(0, 100%, 0);
    opacity: 0;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }

  .app-button:hover:not([disabled]) .char::after {
    transform: translate3d(0, 0%, 0);
    opacity: 1;
    transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
  }
}

.app-button.secondary {
  &:before {
    background: white;
  }
  .char::after {
    color: hsl(var(--color-primary-light));
  }
  @media (hover: hover) {
    &:not([disabled]):hover:after {
      background: var(--theme-dark);
    }
    &:not([disabled]):hover .app-icon {
      stroke: hsl(var(--color-primary-light));
    }
  }
}

.app-button[disabled][type]::after {
  opacity: 1;
  background: var(--theme-light);
  box-shadow: inset 0 0 0 1px var(--theme-dark);
}
</style>
