import url from "@/utils/url";

export default {
  id: "cumbres-del-volcan",
  title: "Cumbres Del Volcán",
  subtitle: "Bed & Breakfast in El Salvador",
  projectUrl: "https://cumbresdelvolcan.com",
  color: {
    highlight: "hsla(0, 15%, 85%, 1)",
    light: "hsla(86, 20%, 92%, 1)",
    dark: "#022601",
    gradient:
      "linear-gradient(45deg, hsla(0, 15%, 80%, 1), hsla(0, 15%, 94%, 1))",
  },
  thumb: {
    src: url("cumbres/cumbres-thumb.webp"),
    width: 800,
    height: 1280,
  },
  hero: {
    src: url("cumbres/cumbres-header.webp"),
  },
  intro: [
    {
      title: "Description",
      text: "Cumbres del Volcan is a family owned and opeated hostel from San Salvador, El Salvador. It operates with two locations in San Salvador and Colonia Flor Blanca. The task was to redesign the site with a new look and develop the site with a new front-end.",
    },
    {
      title: "Roles",
      list: [
        "Web Design",
        "Creative Direction",
        "Front-end Development",
        "Logo Design",
      ],
    },
  ],
  breakdown: [
    {
      layout: "layout-full",
      text: {
        title: "Logo Redesign",
      },
    },
    {
      layout: "layout-half",
      text: {
        subtitle: "Design Iterations",
        summary:
          "There was an opportunity to redesign the logo for a cleaner volcano illustration.  I designed a few concepts and here is what I proposed.",
      },
      media: {
        videos: [
          {
            placeholder: url("cumbres/cumbres-logos-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/cumbres%2Fcumbres-logos.mp4?alt=media&token=36af92cd-4712-4573-b71c-ea333b42c1a0",
            alt: "",
          },
        ],
      },
    },
    {
      layout: "layout-half reversed",
      text: {
        subtitle: "Logo Delivered",
        summary:
          "The final logo chosen by the client against the new color pallet. A volcano of San Salvador, El Salvador.",
      },
      media: {
        videos: [
          {
            placeholder: url("cumbres/cumbres-logo-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/cumbres%2Fcumbres-logo.mp4?alt=media&token=09f8f372-6db0-4947-9090-98dc27d7331a",
            alt: "",
          },
        ],
      },
    },
    {
      layout: "layout-full",
      media: {
        images: [
          {
            src: url("cumbres/cumbres-logo-hat.webp"),
            alt: "",
          },
        ],
      },
      style: {
        marginBottom: "var(--gutted)",
      },
    },
    {
      text: {
        subtitle: "New Home Page",
        summary:
          "The design direction was to my control. The site was to serve as an information hub, gallery and to accept reservations.",
      },
      media: {
        images: [
          {
            src: url("cumbres/cumbres-site-1.webp"),
            alt: "Home Page",
          },
        ],
      },
      layout: "layout-hero",
    },
    {
      media: {
        images: [
          { src: url("cumbres/cumbres-site-2.webp") },
          { src: url("cumbres/cumbres-site-3.webp") },
          {
            src: url("cumbres/cumbres-site-4.webp"),
            style: { marginBottom: "var(--gutted)" },
          },
          { src: url("cumbres/cumbres-site-5.webp"), alt: "Gallery Page" },
          {
            src: url("cumbres/cumbres-site-6.webp"),
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("cumbres/cumbres-site-7.webp"),
            alt: "Accommodation Page",
          },
          { src: url("cumbres/cumbres-site-8.webp") },
          { src: url("cumbres/cumbres-site-9.webp") },
          { src: url("cumbres/cumbres-site-10.webp") },
          { src: url("cumbres/cumbres-site-11.webp") },
        ],
        videos: [
          {
            autoplay: true,
            deviceType: "Android",
            placeholder: url("cumbres/cumbres-mobile-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/cumbres%2Fcumbres-mobile.mp4?alt=media&token=ef72f7e6-765c-4bb2-97b6-f355e1da73d0",
          },
        ],
      },
      layout: "webpage-body",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
    {
      layout: "layout-full",
      media: {
        videos: [
          {
            placeholder: url("cumbres/cumbres-desktop-wall-placeholder.webp"),
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/cumbres%2Fcumbres-desktop-wall.mp4?alt=media&token=83c0e655-80fb-4ddc-8914-11ae02ebb67b",
          },
        ],
      },
    },
    {
      text: {
        subtitle: "Mobile Designs",
      },
      media: [
        { src: url("cumbres/cumbres-mobile-1.webp") },
        { src: url("cumbres/cumbres-mobile-2.webp") },
        { src: url("cumbres/cumbres-mobile-3.webp") },
        { src: url("cumbres/cumbres-mobile-4.webp") },
        { src: url("cumbres/cumbres-mobile-5.webp") },
        { src: url("cumbres/cumbres-mobile-6.webp") },
      ],
      layout: "layout-three-col",
      style: {
        marginBottom: "var(--gutted)",
      },
    },
  ],
};
