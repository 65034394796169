<template>
    <svg viewBox='0 0 16 16' class='app-icon' :data-icon='name'>
        <g v-if='name === `arrow-top-right`'>
            <line x1='14.1' y1='1.6' x2='1.1' y2='14.6'/>
            <path d='M7.1,1.6h7v7'/>
        </g>
        <g v-if='name === `arrow-right`'>
            <line x1='0.5' y1='8.5' x2='15.5' y2='8.5'/>
            <polyline points='10.5,3.5 15.5,8.5 10.5,13.5 '/>
        </g>
        <g v-if='name === `file`'>
            <line x1='3.5' y1='3.5' x2='10.5' y2='3.5'></line>
            <line x1='3.5' y1='6.5' x2='10.5' y2='6.5'></line>
            <line x1='3.5' y1='9.5' x2='10.5' y2='9.5'></line>
            <path d='M13.5,10.5v-10h-13 v13c0,1.105,0.895,2,2,2h0h0c1.105,0,2-0.895,2-2v-1h11V13c0,1.381-1.119,2.5-2.5,2.5H2.5'></path>
        </g>
        <g v-if='name === `instagram`' style='stroke: none'>
            <circle fill="white" cx="12.145" cy="3.892" r="0.96"></circle>
            <path d="M8,12c-2.206,0-4-1.794-4-4s1.794-4,4-4s4,1.794,4,4S10.206,12,8,12z M8,6C6.897,6,6,6.897,6,8 s0.897,2,2,2s2-0.897,2-2S9.103,6,8,6z"></path>
            <path fill="white" d="M12,16H4c-2.056,0-4-1.944-4-4V4c0-2.056,1.944-4,4-4h8c2.056,0,4,1.944,4,4v8C16,14.056,14.056,16,12,16z M4,2C3.065,2,2,3.065,2,4v8c0,0.953,1.047,2,2,2h8c0.935,0,2-1.065,2-2V4c0-0.935-1.065-2-2-2H4z"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'app-icon',
    props: {
        name: {
            type: String,
            default: 'arrow-top-right'
        }
    }
}    
</script>

<style lang='scss'>

@import '../styles/vars';
@import '../styles/mixins';

.app-icon {
    width: vw(16);
    min-width: 12px;
    max-width: 19px;
    height: 100%;
    object-fit: contain;
    fill: none; 
    stroke: var(--theme-dark);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
}
</style>
