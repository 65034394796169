import url from "@/utils/url";

export default {
  id: "archives",
  title: "Archives",
  projectUrl: null,
  color: {
    highlight: "hsl(48, 10%, 86%)",
    light: "hsl(var(--color-font-light))",
    dark: "hsl(var(--color-font-dark))",
    gradient: "var(--color-gradient)",
  },
  thumb: {
    src: "",
  },
  hero: {
    src: "",
  },
  breakdown: [
    {
      text: {
        title: "UI Interactions",
      },
      media: {
        images: [
          [
            {
              src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/archives%2Fsony.mp4?alt=media&token=1bfa5731-3417-4662-a568-39b2a9c2ce94",
              alt: "Sony",
            },
          ],
          [
            {
              src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/archives%2Foverwatch.mp4?alt=media&token=b8a2a80d-4288-4025-a56a-29c71352f0d3",
              alt: "Blizzard: Overwatch",
            },
          ],
        ],
      },
      layout: "layout-two-col",
    },
    {
      text: {
        title: "Editorial Lettering",
        summary:
          "Created for long-form editorial while at DeviantArt. Served as the header piece of an introduction to the topic.",
      },
      media: {
        images: [
          {
            src: "https://firebasestorage.googleapis.com/v0/b/mario-luevanos.appspot.com/o/archives%2Fincoming-title.mp4?alt=media&token=c8317d3c-bb40-4570-8411-e91d809e4f1b",
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/giger-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/robots-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/mayan-doomsday-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/slender-man-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/you-are-not-alone-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/lady-gaga-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/surrealism-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/drones-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/demonology-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
          {
            src: url("archives/superman-title.webp"),
            alt: " ",
            style: { marginBottom: "var(--gutted)" },
          },
        ],
      },
      layout: "layout-full",
    },
    {
      media: {
        images: [
          [
            {
              src: url("archives/i-need-feminism-title.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("archives/ansi-ascii-title.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("archives/bloody-crowns-title.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            { src: url("archives/earthday-2012-title.webp"), alt: " " },
          ],
          [
            {
              src: url("archives/comic-con-tardis-title.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("archives/dantes-inferno-title.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            {
              src: url("archives/etch-a-sketch-title.webp"),
              alt: " ",
              style: { marginBottom: "var(--gutted)" },
            },
            { src: url("archives/wayne-white-title.webp"), alt: " " },
          ],
        ],
      },
      layout: "layout-two-col",
    },
    {
      text: {
        title: "Long-Form Editorial",
        summary: "Some designs from the past, worked on the front-end as well.",
      },
      media: {
        images: [{ src: url("archives/da-wall.webp") }],
      },
      layout: "layout-full",
      style: { marginBottom: "var(--gutted)" },
    },
    {
      media: [
        { src: url("archives/artstroke.webp") },
        { src: url("archives/ansi-ascii.webp") },
        { src: url("archives/cinema-makeup-school.webp") },
        { src: url("archives/halo.webp") },
        { src: url("archives/clive-barker.webp") },
        { src: url("archives/getty.webp") },
        { src: url("archives/toshiba.webp") },
        { src: url("archives/overwatch.webp") },
        { src: url("archives/superman.webp") },
        { src: url("archives/surrealism.webp") },
        { src: url("archives/sunstone.webp") },
        { src: url("archives/zee-captain.webp") },
      ],
      layout: "layout-three-col",
      style: { marginBottom: "var(--gutted)" },
    },
  ],
};
