const yearsExperience = () => {
  const currentYear = new Date().getFullYear();
  const since = 2005;
  return currentYear - since;
};

export default {
  shortBio: `Hello, I’m Mario Luevanos, a multidisciplinary Front-End Developer & UI Designer with ${yearsExperience()} years of experience. I specialize in design, development & creative direction. I generate and execute solutions from concept—to—production.`,
  experience: {
    header: "In the past I've worked with",
    companies: [
      "deviantart",
      "sony",
      "laika",
      "red",
      "toshiba",
      "bandai",
      "adobe",
      "blizzard",
      "microsoft",
      "universal",
    ],
  },
  specialize: {
    header: "Specializing in",
    design: [
      "Web Design",
      "Editorial Design",
      "UI Design",
      "UX Design",
      "Interaction Design",
      "Design Systems",
      "Packaging Design",
      "Identity Systems",
    ],
    development: [
      "Front End Development",
      "Back End Development (Node.js)",
      "REST API Development",
      "Any Framework (Vue, Ember, React, Angular)",
      "Design & author bespoke CSS",
      "Scalable projects with TypeScript",
      "Progressive Web Apps",
      "Web & DOM APIs",
      "Canvas & WebGL",
      "Modern JAM Stack Development",
    ],
    creative: [
      "Art Direction",
      "Branding",
      "Typography & Layout",
      "Research & Strategy",
      "Customer Journeys",
      "Lettering & Calligraphy",
    ],
  },
  resume:
    "From prototypes to deployment, I create compelling digital experiences that tell your brand’s unique story, working with you to create successful products and reach new audience.",
};
