export default {
  render(h) {
    const stopStart = h("stop", {
      style: "stop-color: hsl(var(--color-secondary-light)); stop-opacity: 1",
      attrs: {
        offset: "10%",
      },
    });
    const stopEnd = h("stop", {
      style: "stop-color: hsl(var(--color-primary-light)); stop-opacity: 1",
      attrs: {
        offset: "60%",
      },
    });

    const linearGradient = h(
      "linearGradient",
      {
        attrs: {
          id: "theme-gradient",
          x1: "100%",
          y1: "0%",
          x2: "100%",
          y2: "100%",
        },
      },
      [stopStart, stopEnd]
    );

    const defs = h("defs", [linearGradient]);

    const path = h("path", {
      attrs: {
        d: `M45.5,18.5l-0.1-0.8c2.2-0.3,4.6,0.8,4.6,3.5c0,3.5-5.1,7-8.1,8.6c-1.3,0.7-3.9,1.5-7.4,1.6c-0.6,1.9-2.1,5.1-6.3,10.1
                        c2.3,2.3,7.2,6.5,10.3,6.5c1.2,0,2.3-2.9,2.5-6.1h0.8c-0.3,6.7-5.7,11.2-9.9,16.2l-0.6-0.6l2.7-3c-4.3-1.3-8-4-11.9-7.6
                        c-5.3,4-11.8,6-17,10.6l-0.6-0.6l3.2-2.5C4,49.7-4.1,37.5,2.5,32.9c2.4-1.6,4.9-2.9,7.5-4.6C8.9,24,7.6,18.3,6.9,14.3
                        c-1.2,0.4-2.3,0.7-3.2,0.8l-0.2-0.8c7.6-1.4,13.7-6.1,18.9-12.3l0.7,0.5l-1.9,2.2c3.5,2.9,8.8,7.9,8.2,10.2
                        c-1.2,3.9-6.7,7.1-11.6,9.9c0.6,1.3,1.3,2.9,1.9,4c3.2-2.5,6.9-5.3,11.2-5.3c4,0,7.7,1.3,12,1.3c3.2,0,6.3-0.8,6.3-3.8
                        C49.2,19.1,47.2,18.3,45.5,18.5z M10.4,29.1c-2.5,1.7-3.9,3.5-3.9,5.5c0,6.6,7.1,18.1,15.1,11.5C17.3,40.9,14.1,35.8,10.4,29.1z
                        M15.5,10.1c-0.8,0-1.1,0.7-1.1,1.9c0,3.9,1.3,7.7,3.1,12c3.2-1.9,5.5-3.5,5.5-4.4C23,16.9,18.5,10.1,15.5,10.1z M24,28.1
                        c-1.3,0-2.3,0.6-3.9,1.6c1.9,3.7,4.6,7.6,7.5,10.9c3.5-3.9,5.3-7.6,5.9-9.5C30.5,30.4,27.3,28.1,24,28.1z`,
      },
    });

    const children = [defs, path];

    return h(
      "svg",
      {
        class: "ampersand",
        attrs: {
          viewBox: "0 0 50 60",
        },
        style: `fill: url(#theme-gradient)`,
      },
      children
    );
  },
};
